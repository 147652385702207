var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import { tap } from "rxjs";
import { TYPES } from "di/types";
/**
 * Models a behavior that makes the consent persistent every time it changes
 *
 */
var ManageI18nOnDocumentLanguageChangeBehavior = /** @class */ (function () {
    function ManageI18nOnDocumentLanguageChangeBehavior(i18n, logger, documentLanguage) {
        this.i18n = i18n;
        this.logger = logger;
        this.documentLanguage = documentLanguage;
    }
    ManageI18nOnDocumentLanguageChangeBehavior.prototype.bind = function () {
        var _this = this;
        /*
         * Change translations when document language changes
         */
        this.subscription = this.documentLanguage.asObservable()
            .pipe(tap(function (documentLanguageCode) {
            _this.logger.debug("[handleI18nOnDocumentLanguageChangeBehavior] Document language is '".concat(documentLanguageCode, "' -> about to set I18n support"));
        })).subscribe(function (documentLanguageCode) {
            _this.i18n.setLanguageCode(documentLanguageCode);
        });
    };
    ManageI18nOnDocumentLanguageChangeBehavior.prototype.unbind = function () {
        this.subscription.unsubscribe();
    };
    ManageI18nOnDocumentLanguageChangeBehavior = __decorate([
        injectable(),
        __param(0, inject(TYPES.i18n)),
        __param(1, inject(TYPES.logger)),
        __param(2, inject(TYPES.documentLanguage)),
        __metadata("design:paramtypes", [Object, Object, Object])
    ], ManageI18nOnDocumentLanguageChangeBehavior);
    return ManageI18nOnDocumentLanguageChangeBehavior;
}());
export { ManageI18nOnDocumentLanguageChangeBehavior };
