var DomUtils = /** @class */ (function () {
    function DomUtils() {
    }
    DomUtils.copyAttributes = function (sourceEl, targetEl) {
        Array.from(sourceEl.attributes).forEach(function (attribute) {
            targetEl.setAttribute(attribute.nodeName, attribute.nodeValue);
        });
    };
    DomUtils.copyContent = function (sourceEl, targetEl) {
        targetEl.innerHTML = sourceEl.innerHTML;
    };
    DomUtils.replaceElement = function (replacedEl, replacementEl) {
        var parentElement = replacedEl.parentNode;
        parentElement.insertBefore(replacementEl, replacedEl);
        parentElement.removeChild(replacedEl);
    };
    return DomUtils;
}());
export { DomUtils };
