import { SettingsDialogCategoryServiceCookie } from "./SettingsDialogCategoryServiceCookie";
var SettingsDialogCategoryService = /** @class */ (function () {
    function SettingsDialogCategoryService(service, languageCode) {
        this.service = service;
        this.languageCode = languageCode;
    }
    Object.defineProperty(SettingsDialogCategoryService.prototype, "id", {
        get: function () {
            return this.service.getId();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingsDialogCategoryService.prototype, "name", {
        get: function () {
            return this.service.getName(this.languageCode);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingsDialogCategoryService.prototype, "description", {
        get: function () {
            return this.service.getDescription(this.languageCode);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingsDialogCategoryService.prototype, "cookies", {
        get: function () {
            var _this = this;
            if (typeof this._cookies === 'undefined') {
                this._cookies = this.service.cookies().map(function (cookie) {
                    return new SettingsDialogCategoryServiceCookie(cookie, _this.languageCode);
                });
            }
            return this._cookies;
        },
        enumerable: false,
        configurable: true
    });
    return SettingsDialogCategoryService;
}());
export { SettingsDialogCategoryService };
