var ConfigRemovalInfosGenerator = /** @class */ (function () {
    function ConfigRemovalInfosGenerator(config) {
        this.config = config;
    }
    ConfigRemovalInfosGenerator.prototype.generateRemovalInfosForCookie = function (cookieName) {
        try {
            var cookie = this.config.getMatchingCookie(cookieName);
            return cookie.getRemovalInfos();
        }
        catch (e) {
            //Given cookie not matched in configuration: we have no removal info from this generator
            return [];
        }
    };
    return ConfigRemovalInfosGenerator;
}());
export { ConfigRemovalInfosGenerator };
