var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import { Config } from "core/entities/config/Config";
import { Subject } from "rxjs";
import { CookieUtils } from "utils/CookieUtils";
import { ConsentVisitor } from "./ConsentVisitor";
import { TYPES } from "di/types";
var ConsentBasedCookieCleanupService = /** @class */ (function () {
    function ConsentBasedCookieCleanupService(userConsent, config, cookieRemovalService, cookieSupport) {
        this.userConsent = userConsent;
        this.config = config;
        this.cookieRemovalService = cookieRemovalService;
        this.cookieSupport = cookieSupport;
        this.removalFailuresSubject = new Subject();
    }
    Object.defineProperty(ConsentBasedCookieCleanupService.prototype, "removalFailures$", {
        get: function () {
            return this.removalFailuresSubject;
        },
        enumerable: false,
        configurable: true
    });
    ConsentBasedCookieCleanupService.prototype.cleanup = function () {
        var _this = this;
        this.cookieSupport.getAll().forEach(function (cookie) {
            var cookieName = CookieUtils.getName(cookie);
            var consentVisitor = new ConsentVisitor(cookieName, _this.config);
            _this.userConsent.get().accept(consentVisitor);
            if (consentVisitor.isCookieToBeRemoved()) {
                _this.cookieRemovalService.removeCookie(cookieName);
                try {
                    _this.cookieSupport.assertCookieNotPresent(cookieName);
                }
                catch (e) {
                    _this.removalFailuresSubject.next(cookie);
                }
            }
        });
    };
    ConsentBasedCookieCleanupService = __decorate([
        injectable(),
        __param(0, inject(TYPES.userConsent)),
        __param(1, inject(TYPES.config)),
        __param(2, inject(TYPES.cookieRemovalService)),
        __param(3, inject(TYPES.cookieSupport)),
        __metadata("design:paramtypes", [Object, Config, Object, Object])
    ], ConsentBasedCookieCleanupService);
    return ConsentBasedCookieCleanupService;
}());
export { ConsentBasedCookieCleanupService };
