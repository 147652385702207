/*
 * Models a scenario where the user has expressed a consent
 * about to not reject any cookie
 */
var UserAcceptAllConsent = /** @class */ (function () {
    function UserAcceptAllConsent() {
    }
    UserAcceptAllConsent.prototype.accept = function (visitor) {
        visitor.visitUserAcceptAllConsent(this);
    };
    return UserAcceptAllConsent;
}());
export { UserAcceptAllConsent };
