var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import { BehaviorSubject } from "rxjs";
import { TYPES } from "di/types";
var Router = /** @class */ (function () {
    function Router(logger) {
        this.logger = logger;
        this.statesSubject = new BehaviorSubject(undefined);
        this.transitionInCourse = false;
    }
    Object.defineProperty(Router.prototype, "states$", {
        get: function () {
            return this.statesSubject.asObservable();
        },
        enumerable: false,
        configurable: true
    });
    Router.prototype.transitionToInternal = function (newState) {
        this.transitionInCourse = true;
        var currentState = this.getCurrentState();
        /*
         * 1)	Ensure we can leave the current state
         */
        if (typeof currentState !== 'undefined') {
            this.logger.debug("[router] About to leave state '".concat(currentState, "'"));
            var canLeaveCurrentState = currentState.onAboutToLeave();
            if (!canLeaveCurrentState) {
                this.logger.debug("[router] Cannot leave state '".concat(currentState, "'"));
                throw Error("Cannot leave state '".concat(currentState, "'"));
            }
        }
        /*
         * 2)	Ensure we can enter the new state
         */
        if (typeof newState !== 'undefined') {
            this.logger.debug("[router] About to enter state '".concat(newState, "'"));
            var canEnterNewState = newState.onAboutToEnter();
            if (!canEnterNewState) {
                this.logger.debug("[router] Cannot enter state '".concat(newState, "'"));
                throw Error("Cannot enter state '".concat(newState, "'"));
            }
        }
        /*
         * 3)	Notify current state that we're leaving it
         */
        if (typeof currentState !== 'undefined') {
            this.logger.debug("[router] Leaving state '".concat(currentState, "'"));
            currentState.onLeaving();
        }
        /*
         * 4)	Notify new state that we're entering it
         */
        if (typeof newState !== 'undefined') {
            this.logger.debug("[router] Entering state '".concat(newState, "'"));
            newState.onEntering();
        }
        /*
         * 5)	Enter new state
         */
        this.statesSubject.next(newState);
        /*
         * 6)	Notify previous state that it has been left
         */
        if (typeof currentState !== 'undefined') {
            this.logger.debug("[router] Left state '".concat(currentState, "'"));
            currentState.onLeft();
        }
        /*
         * 7)	Notify new state that it has been entered
         */
        if (typeof newState !== 'undefined') {
            this.logger.debug("[router] Entered state '".concat(newState, "'"));
            newState.onEntered();
        }
        this.transitionInCourse = false;
    };
    Router.prototype.transitionTo = function (newState) {
        var _this = this;
        if (this.transitionInCourse) {
            //Wait for the current transition to finish
            setTimeout(function () {
                _this.transitionToInternal(newState);
            }, 0);
        }
        else {
            //Execute immediately
            this.transitionToInternal(newState);
        }
    };
    Router.prototype.getCurrentState = function () {
        return this.statesSubject.getValue();
    };
    Router = __decorate([
        injectable(),
        __param(0, inject(TYPES.logger)),
        __metadata("design:paramtypes", [Object])
    ], Router);
    return Router;
}());
export { Router };
