var ConsentVisitor = /** @class */ (function () {
    function ConsentVisitor(cookieName, config) {
        this.cookieName = cookieName;
        this.config = config;
    }
    /*
     * VISITOR IMPLEMENTATION
     */
    ConsentVisitor.prototype.visitUnknownConsent = function (consent) {
        //Leave all cookies until we don't know which is the user preference
        this._isCookieToBeRemoved = false;
    };
    ConsentVisitor.prototype.visitMissingConsent = function (consent) {
        try {
            var category = this.config.getCategoryMatchingCookie(this.cookieName);
            var categoryDescriptor = category.getDescriptor();
            if (categoryDescriptor.needed) {
                //The cookie is needed: leave it
                this._isCookieToBeRemoved = false;
            }
            else {
                var unneededCategoryDescriptor = categoryDescriptor;
                if (unneededCategoryDescriptor.wanted) {
                    //The user preference is missing, but the cookie is wanted, so leave it
                    this._isCookieToBeRemoved = false;
                }
                else {
                    //The user preference is missing and the cookie is not wanted, so remove it
                    this._isCookieToBeRemoved = true;
                }
            }
        }
        catch (e) {
            //The cookie's category is not known: leave it (even because we do not have complete removal informations...)
            this._isCookieToBeRemoved = false;
        }
    };
    ConsentVisitor.prototype.visitUserRejectAllConsent = function (consent) {
        try {
            var category = this.config.getCategoryMatchingCookie(this.cookieName);
            var categoryDescriptor = category.getDescriptor();
            if (categoryDescriptor.needed) {
                //The cookie is needed: leave it
                this._isCookieToBeRemoved = false;
            }
            else {
                //User do not want any cookie except needed ones: remove it
                this._isCookieToBeRemoved = true;
            }
        }
        catch (e) {
            //The cookie's category is not known: leave it (even because we do not have complete removal informations...)
            this._isCookieToBeRemoved = false;
        }
    };
    ConsentVisitor.prototype.visitUserAcceptAllConsent = function (consent) {
        //User has accepted all cookies, so do not remove any of them
        this._isCookieToBeRemoved = false;
    };
    ConsentVisitor.prototype.visitUserCategoryBasedConsent = function (consent) {
        try {
            var category = this.config.getCategoryMatchingCookie(this.cookieName);
            var categoryDescriptor = category.getDescriptor();
            if (categoryDescriptor.needed) {
                //The cookie is needed: leave it
                this._isCookieToBeRemoved = false;
            }
            else {
                //Leave the cookie if it belongs to a category allowed by the user
                if (consent.isCategoryAllowed(category.getId())) {
                    this._isCookieToBeRemoved = false;
                }
                else {
                    this._isCookieToBeRemoved = true;
                }
            }
        }
        catch (e) {
            //The cookie's category is not known: leave it (even because we do not have complete removal informations...)
            this._isCookieToBeRemoved = false;
        }
    };
    /*
     * PUBLIC INTERFACE
     */
    ConsentVisitor.prototype.isCookieToBeRemoved = function () {
        return this._isCookieToBeRemoved;
    };
    return ConsentVisitor;
}());
export { ConsentVisitor };
