import { Config } from "core/entities/config/Config";
import "./style.scss";
/*
 * LOAD POLYFILLS
 */
import "./polyfills/reflect-metadata";
import "./polyfills/es6-shim";
import "./polyfills/harmony-proxy";
/*
 * SETUP DI
 */
import { container } from "./di/container";
import "./di/bootstrap";
import { TYPES } from "./di/types";
/*
 * PUBLIC INTERFACE
 */
var _global = (window /* browser */ || global /* node */);
_global.hiCookie = function (configDescriptor) {
    var config = new Config(configDescriptor);
    container.bind(TYPES.config).toConstantValue(config);
    var app = container.get(TYPES.app);
    app.run();
};
