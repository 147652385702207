var ConsentVisitor = /** @class */ (function () {
    function ConsentVisitor(category) {
        this.category = category;
    }
    /*
     * VISITOR IMPLEMENTATION
     */
    ConsentVisitor.prototype.visitUnknownConsent = function (consent) {
        if (this.category.getDescriptor().needed) {
            //Needed categories are always one
            this._isToggleOn = true;
        }
        else {
            var unneededCategoryDescriptor = this.category.getDescriptor();
            //For non needed categories honour configuration
            this._isToggleOn = unneededCategoryDescriptor.checked;
        }
    };
    ConsentVisitor.prototype.visitMissingConsent = function (consent) {
        if (this.category.getDescriptor().needed) {
            //Needed categories are always one
            this._isToggleOn = true;
        }
        else {
            var unneededCategoryDescriptor = this.category.getDescriptor();
            //For non needed categories honour configuration
            this._isToggleOn = unneededCategoryDescriptor.checked;
        }
    };
    ConsentVisitor.prototype.visitUserRejectAllConsent = function (consent) {
        if (this.category.getDescriptor().needed) {
            //Needed categories are always on
            this._isToggleOn = true;
        }
        else {
            //Everything is toggled of except needed categories
            this._isToggleOn = false;
        }
    };
    ConsentVisitor.prototype.visitUserAcceptAllConsent = function (consent) {
        //All categories are on
        this._isToggleOn = true;
    };
    ConsentVisitor.prototype.visitUserCategoryBasedConsent = function (consent) {
        if (this.category.getDescriptor().needed) {
            //Needed categories are always one
            this._isToggleOn = true;
        }
        else {
            //For non needed categories perform attribution from user consent
            this._isToggleOn = consent.isCategoryAllowed(this.category.getId());
        }
    };
    /*
     * PUBLIC INTERFACE
     */
    ConsentVisitor.prototype.isToggleOn = function () {
        return this._isToggleOn;
    };
    return ConsentVisitor;
}());
export { ConsentVisitor };
