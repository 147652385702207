var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import { lazyInject } from "di/lazyInject";
import { TYPES } from "di/types";
var AvailableConsentState = /** @class */ (function () {
    function AvailableConsentState(logger) {
        this.logger = logger;
    }
    AvailableConsentState.prototype.toString = function () {
        return "AvailableConsentState";
    };
    /*
     * STATE IMPLEMENTATION
     */
    AvailableConsentState.prototype.onAboutToEnter = function () {
        //This state can be entered freely
        return true;
    };
    AvailableConsentState.prototype.onEntering = function () {
        //Nothing to do
    };
    AvailableConsentState.prototype.onEntered = function () {
        this.component.init();
    };
    AvailableConsentState.prototype.onAboutToLeave = function () {
        //This state can be left freely
        return true;
    };
    AvailableConsentState.prototype.onLeaving = function () {
        //Nothing to do
    };
    AvailableConsentState.prototype.onLeft = function () {
        this.component.destroy();
    };
    __decorate([
        lazyInject(TYPES.availableConsentBannerComponent),
        __metadata("design:type", Object)
    ], AvailableConsentState.prototype, "component", void 0);
    AvailableConsentState = __decorate([
        injectable(),
        __param(0, inject(TYPES.logger)),
        __metadata("design:paramtypes", [Object])
    ], AvailableConsentState);
    return AvailableConsentState;
}());
export { AvailableConsentState };
