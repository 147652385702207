import { IsCategoryAllowedConsentVisitor } from "../entities/consent/IsCategoryAllowedConsentVisitor";
import { default as $ } from "cash-dom";
var DATA_ATTR_HI_COOKIE = "hi-cookie";
var AbstractConsentBasedMarkupProcessor = /** @class */ (function () {
    function AbstractConsentBasedMarkupProcessor(consent, config) {
        this.consent = consent;
        this.config = config;
    }
    /*
     * INTERNALS
     */
    AbstractConsentBasedMarkupProcessor.prototype.enableElement = function (el) {
        if (this.isEnabled(el)) {
            return;
        }
        this.enableElementInternal(el);
    };
    AbstractConsentBasedMarkupProcessor.prototype.disableElement = function (el) {
        if (!this.isEnabled(el)) {
            return;
        }
        this.disableElementInternal(el);
    };
    AbstractConsentBasedMarkupProcessor.prototype.processElement = function (el) {
        /*
         * 1)	Obtain the list of categories from the element
         */
        var _this = this;
        var categoriesIdsStr = "" + el.data(DATA_ATTR_HI_COOKIE);
        var categoriesIds = categoriesIdsStr.trim().split(" ");
        /*
         * 2)	Check if the user consent is compatible with the aforementioned categories
         */
        /*var isAtLeastOneCategoryAllowed: boolean = categoriesIds.some((categoryId: CategoryId): boolean => {
            return this.isCategoryAllowed(categoryId);
        });*/
        var isAtLeastOneCategoryNotAllowed = categoriesIds.some(function (categoryId) {
            var isCategoryAllowedConsentVisitor = new IsCategoryAllowedConsentVisitor(categoryId, _this.config);
            _this.consent.accept(isCategoryAllowedConsentVisitor);
            return !isCategoryAllowedConsentVisitor.isCategoryAllowed();
        });
        /*
         * 3)	Alter markup in accordance to the consent
         */
        if (!isAtLeastOneCategoryNotAllowed) {
            //All categories are allowed: enable element
            this.enableElement(el);
        }
        else {
            //At least one category is not allowed: disable element
            this.disableElement(el);
        }
    };
    /*
     * PUBLIC INTERFACE
     */
    AbstractConsentBasedMarkupProcessor.prototype.processMarkup = function () {
        /*
         * 1)	Select the target elements that are flagged to be managed by this service
         */
        var els = $(this.getTargetElementsSelector())
            .filter(function () {
            var ithEl = $(this);
            var attrValue = ithEl.data(DATA_ATTR_HI_COOKIE);
            return (typeof attrValue !== "undefined");
        });
        /*
         * 2)	Loop every element and process them
         */
        var me = this;
        els.each(function () {
            var el = $(this);
            me.processElement(el);
        });
    };
    return AbstractConsentBasedMarkupProcessor;
}());
export { AbstractConsentBasedMarkupProcessor };
