var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import { AbstractConsentPersistanceService } from "./AbstractConsentPersistanceService";
import { Config } from "core/entities/config/Config";
import { TYPES } from "di/types";
var LOCAL_STORAGE_KEY__SERIALIZED_CONSENT = "hi-cookie_serialized-consent";
var LOCAL_STORAGE_KEY__SERIALIZED_CONSENT_TIMESTAMP = "hi-cookie_serialized-consent-timestamp";
var ConsentTimestamp = /** @class */ (function () {
    /*
     * INSTANCE STUFF
     */
    /**
     *
     */
    function ConsentTimestamp(value) {
        this.value = value;
    }
    /*
     * STATIC STUFF
     */
    ConsentTimestamp.now = function () {
        var value = Math.floor(Date.now() / 1000);
        return new ConsentTimestamp(value);
    };
    ConsentTimestamp.load = function () {
        var value = localStorage.getItem(LOCAL_STORAGE_KEY__SERIALIZED_CONSENT_TIMESTAMP);
        if (value == null) {
            throw Error("Data not available");
        }
        return new ConsentTimestamp(parseInt(value));
    };
    ConsentTimestamp.prototype.getValue = function () {
        return this.value;
    };
    /**
     *
     * @returns The age in seconds
     */
    ConsentTimestamp.prototype.getAge = function () {
        var now = ConsentTimestamp.now();
        var age = now.getValue() - this.value;
        return age;
    };
    ConsentTimestamp.prototype.remove = function () {
        localStorage.removeItem(LOCAL_STORAGE_KEY__SERIALIZED_CONSENT_TIMESTAMP);
    };
    ConsentTimestamp.prototype.save = function () {
        localStorage.setItem(LOCAL_STORAGE_KEY__SERIALIZED_CONSENT_TIMESTAMP, this.value);
    };
    return ConsentTimestamp;
}());
/**
 * Provides a LocalStorage-based implementation of the service that handles
 * consent persistance
 *
 */
var LocalStorageConsentPersistanceService = /** @class */ (function (_super) {
    __extends(LocalStorageConsentPersistanceService, _super);
    function LocalStorageConsentPersistanceService(logger, config) {
        var _this = _super.call(this, logger) || this;
        _this.config = config;
        return _this;
    }
    LocalStorageConsentPersistanceService.prototype.write = function (data) {
        localStorage.setItem(LOCAL_STORAGE_KEY__SERIALIZED_CONSENT, data);
        var consentTimestamp = ConsentTimestamp.now();
        consentTimestamp.save();
        this.logger.debug("[consentPersistanceService] Consent stored");
    };
    LocalStorageConsentPersistanceService.prototype.read = function () {
        var data = localStorage.getItem(LOCAL_STORAGE_KEY__SERIALIZED_CONSENT);
        if (data == null) {
            this.logger.debug("[consentPersistanceService] Consent not available");
            throw Error("Data not available");
        }
        try {
            var consentTimestamp = ConsentTimestamp.load();
            var age = consentTimestamp.getAge();
            var maxAge = this.config.getConsentMaxAge();
            if (age >= maxAge) {
                this.logger.debug("[consentPersistanceService] Consent is expired since it has age of ".concat(age, " > max age ").concat(maxAge, ": performing cleanup"));
                //Cleanup
                consentTimestamp.remove();
                throw Error("Consent has expired");
            }
        }
        catch (e) {
            //We don't have a timestamp or it is expired: performing cleanup
            localStorage.removeItem(LOCAL_STORAGE_KEY__SERIALIZED_CONSENT);
            throw e;
        }
        return data;
    };
    LocalStorageConsentPersistanceService = __decorate([
        injectable(),
        __param(0, inject(TYPES.logger)),
        __param(1, inject(TYPES.config)),
        __metadata("design:paramtypes", [Object, Config])
    ], LocalStorageConsentPersistanceService);
    return LocalStorageConsentPersistanceService;
}(AbstractConsentPersistanceService));
export { LocalStorageConsentPersistanceService };
