import { container } from "./container";
import { TYPES } from "./types";
import { GettextJsI18nService } from "core/services/i18n/GettextJsI18nService";
container.bind(TYPES.i18n).to(GettextJsI18nService).inSingletonScope();
import { DevLogger } from "core/services/logger/DevLogger";
container.bind(TYPES.logger).to(DevLogger).inSingletonScope(); //DEV MODE
import { ConsentBasedCookieCleanupService } from "core/services/cookieCleanupService/ConsentBasedCookieCleanupService";
container.bind(TYPES.cookieCleanupService).to(ConsentBasedCookieCleanupService).inSingletonScope();
import { LocalStorageConsentPersistanceService } from "core/services/consentPersistanceService/LocalStorageConsentPersistanceService";
container.bind(TYPES.consentPersistanceService).to(LocalStorageConsentPersistanceService).inSingletonScope();
import { CookieRemovalService } from "core/services/cookieRemovalService/CookieRemovalService";
container.bind(TYPES.cookieRemovalService).to(CookieRemovalService).inSingletonScope();
import { DocumentCookieSupport } from "core/services/cookieSupport/DocumentCookieSupport";
container.bind(TYPES.cookieSupport).to(DocumentCookieSupport).inSingletonScope();
import { DefaultUserConsentImpl } from "core/services/userConsent/DefaultUserConsentImpl";
container.bind(TYPES.userConsent).to(DefaultUserConsentImpl).inSingletonScope();
import { Router } from "core/services/router/Router";
container.bind(TYPES.router).to(Router).inSingletonScope();
import { DocumentLanguage } from "core/services/documentLanguage/DocumentLanguage";
container.bind(TYPES.documentLanguage).to(DocumentLanguage).inSingletonScope();
import { App } from "core/services/app/App";
container.bind(TYPES.app).to(App).inSingletonScope();
import { HandleUserConsentPersistanceBehavior } from "core/behaviors/handleUserConsentPersistanceBehavior/HandleUserConsentPersistanceBehavior";
container.bind(TYPES.handleUserConsentPersistanceBehavior).to(HandleUserConsentPersistanceBehavior).inSingletonScope();
import { RemoveCookiesOnUserConsentChangeBehavior } from "core/behaviors/removeCookiesOnUserConsentChangeBehavior/RemoveCookiesOnUserConsentChangeBehavior";
container.bind(TYPES.removeCookiesOnUserConsentChangeBehavior).to(RemoveCookiesOnUserConsentChangeBehavior).inSingletonScope();
import { HandleMarkupProcessingBehavior } from "core/behaviors/handleMarkupProcessingBehavior/HandleMarkupProcessingBehavior";
container.bind(TYPES.handleMarkupProcessingBehavior).to(HandleMarkupProcessingBehavior).inSingletonScope();
import { ManageI18nOnDocumentLanguageChangeBehavior } from "core/behaviors/manageI18nOnDocumentLanguageChangeBehavior/ManageI18nOnDocumentLanguageChangeBehavior";
container.bind(TYPES.manageI18nOnDocumentLanguageChangeBehavior).to(ManageI18nOnDocumentLanguageChangeBehavior).inSingletonScope();
/***********************
 *
 *		STATES
 *
 **********************/
/*
* rootState
*/
import { RootState } from "core/states/rootState/RootState";
container.bind(TYPES.rootState).to(RootState).inSingletonScope();
/*
* missingConsentState
*/
import { MissingConsentState } from "core/states/missingConsentState/MissingConsentState";
container.bind(TYPES.missingConsentState).to(MissingConsentState).inSingletonScope();
/*
 * availableConsentState
 */
import { AvailableConsentState } from "core/states/availableConsentState/AvailableConsentState";
container.bind(TYPES.availableConsentState).to(AvailableConsentState).inSingletonScope();
/*
 * availableConsentState
 */
import { SettingsState } from "core/states/settingsState/SettingsState";
container.bind(TYPES.settingsState).to(SettingsState).inSingletonScope();
/***********************
 *
 *		COMPONENTS
 *
 **********************/
/*
 * missingConsentDialogComponent
 */
import { Component as MissingConsentDialogComponent } from "core/components/missingConsentDialog/Component";
container.bind(TYPES.missingConsentDialogComponent).to(MissingConsentDialogComponent).inSingletonScope();
/*
 * availableConsentBannerComponent
 */
import { Component as AvailableConsentBannerComponent } from "core/components/availableConsentBanner/Component";
container.bind(TYPES.availableConsentBannerComponent).to(AvailableConsentBannerComponent).inSingletonScope();
/*
 * settingsDialogComponent
 */
import { Component as SettingsDialogComponent } from "core/components/settingsDialog/Component";
container.bind(TYPES.settingsDialogComponent).to(SettingsDialogComponent).inSingletonScope();
