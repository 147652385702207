import { CookiePolicy } from "./CookiePolicy";
import { Service } from "./Service";
import { Category } from "./Category";
export var defaultConsentMaxAge = 60 * 60 * 24 * 30 * 6; //6 Months
var Config = /** @class */ (function () {
    function Config(configDescriptor) {
        this.configDescriptor = configDescriptor;
    }
    Config.prototype.getDescriptor = function () {
        return this.configDescriptor;
    };
    Config.prototype.getCookiePolicy = function () {
        return new CookiePolicy(this.configDescriptor.cookiePolicy);
    };
    Config.prototype.getConsentMaxAge = function () {
        if (typeof this.configDescriptor.consentMaxAge !== 'undefined') {
            return this.configDescriptor.consentMaxAge;
        }
        return defaultConsentMaxAge;
    };
    /*
     * CATEGORIES
     */
    Config.prototype.categoriesIds = function () {
        return Object.keys(this.configDescriptor.categories);
    };
    Config.prototype.getCategoryDescriptorById = function (categoryId) {
        var categoryDescriptor = this.configDescriptor.categories[categoryId];
        if (typeof categoryDescriptor === 'undefined') {
            throw Error("Category ".concat(categoryId, " is not present in configuration"));
        }
        return categoryDescriptor;
    };
    Config.prototype.categories = function () {
        var _this = this;
        if (typeof this._categories === 'undefined') {
            this._categories = [];
            this.categoriesIds().forEach(function (ithCategoryId) {
                var ithCategory = new Category(ithCategoryId, _this);
                _this._categories.push(ithCategory);
            });
        }
        return this._categories;
    };
    Config.prototype.getCategoryById = function (categoryId) {
        var category = this.categories().find(function (category) {
            return (category.getId() == categoryId);
        });
        if (typeof category === 'undefined') {
            throw Error("Category with id '".concat(categoryId, "' not found"));
        }
        return category;
    };
    /*
     * SERVICES
     */
    Config.prototype.servicesIds = function () {
        return Object.keys(this.configDescriptor.services);
    };
    Config.prototype.getServiceDescriptorById = function (serviceId) {
        var serviceDescriptor = this.configDescriptor.services[serviceId];
        if (typeof serviceDescriptor === 'undefined') {
            throw Error("Service ".concat(serviceId, " is not present in configuration"));
        }
        return serviceDescriptor;
    };
    Config.prototype.services = function () {
        var _this = this;
        if (typeof this._services === 'undefined') {
            this._services = [];
            this.servicesIds().forEach(function (ithServiceId) {
                var ithService = new Service(ithServiceId, _this);
                _this._services.push(ithService);
            });
        }
        return this._services;
    };
    Config.prototype.getServiceById = function (serviceId) {
        var service = this.services().find(function (service) {
            return (service.getId() == serviceId);
        });
        if (typeof service === 'undefined') {
            throw Error("Service with id '".concat(serviceId, "' not found"));
        }
        return service;
    };
    Config.prototype.getCategoryMatchingCookie = function (cookieName) {
        try {
            var service = this.getServiceMatchingCookie(cookieName);
            return service.getCategory();
        }
        catch (e) {
            throw Error("Unable to retrieve category of cookie '".concat(cookieName, "'"));
        }
    };
    Config.prototype.getServiceMatchingCookie = function (cookieName) {
        var foundService = this.services().find(function (ithService) {
            if (ithService.hasMatchingCookie(cookieName)) {
                return true;
            }
        });
        if (typeof foundService === 'undefined') {
            throw Error("No Service found that matches cookie '".concat(cookieName, "'"));
        }
        return foundService;
    };
    Config.prototype.getMatchingCookie = function (cookieName) {
        var matchingCookie;
        this.services().every(function (ithService) {
            try {
                matchingCookie = ithService.getMatchingCookie(cookieName);
                return false; //Found! Stop loop
            }
            catch (e) {
                //No cookies matching in this service... ignore and continue search
                return true;
            }
        });
        if (typeof matchingCookie === 'undefined') {
            throw Error("No match found for cookie '".concat(cookieName, "'"));
        }
        return matchingCookie;
    };
    return Config;
}());
export { Config };
