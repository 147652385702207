var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import { AbstractOnUserConsentChangeBehavior } from "core/behaviors/AbstractOnUserConsentChangeBehavior";
import { Config } from "core/entities/config/Config";
import { default as $ } from "cash-dom";
import { MarkupProcessor as IFramesMarkupProcessor } from "core/markupProcessors/iframes/MarkupProcessor";
import { MarkupProcessor as ImgsMarkupProcessor } from "core/markupProcessors/imgs/MarkupProcessor";
import { MarkupProcessor as ScriptsMarkupProcessor } from "core/markupProcessors/scripts/MarkupProcessor";
var RUN_MARKUP_PROCESSORS__EVENT = "hi-cookie:run-markup-processors";
import { TYPES } from "di/types";
var HandleMarkupProcessingBehavior = /** @class */ (function (_super) {
    __extends(HandleMarkupProcessingBehavior, _super);
    function HandleMarkupProcessingBehavior(userConsent, config, logger) {
        var _this = _super.call(this, userConsent) || this;
        _this.config = config;
        _this.logger = logger;
        _this.runMarkupProcessorsEventListener = function (event) {
            _this.logger.debug("[handleMarkupProcessingBehavior] Event '".concat(RUN_MARKUP_PROCESSORS__EVENT, "' received: about to run markup processors"));
            _this.runMarkupProcessors();
        };
        return _this;
    }
    /*
     * OVERRIDES
     */
    HandleMarkupProcessingBehavior.prototype.bind = function () {
        var _this = this;
        _super.prototype.bind.call(this);
        $(document).on('ready', function () {
            _this.logger.debug("[handleMarkupProcessingBehavior] Document ready: about to run markup processors for the first time");
            _this.runMarkupProcessors();
        });
        document.addEventListener(RUN_MARKUP_PROCESSORS__EVENT, this.runMarkupProcessorsEventListener);
    };
    HandleMarkupProcessingBehavior.prototype.unbind = function () {
        _super.prototype.unbind.call(this);
        document.removeEventListener(RUN_MARKUP_PROCESSORS__EVENT, this.runMarkupProcessorsEventListener);
    };
    /*
     * INTERNALS
     */
    HandleMarkupProcessingBehavior.prototype.runMarkupProcessors = function () {
        /*
         * Every time the user consent changes re perform the markup processing
         * with a processor that is specific for the type of elements handled
         * by the concrete implementation of this behavior
         */
        var consent = this.userConsent.get();
        var markupProcessors = [
            new IFramesMarkupProcessor(consent, this.config),
            new ImgsMarkupProcessor(consent, this.config),
            new ScriptsMarkupProcessor(consent, this.config)
        ];
        markupProcessors.forEach(function (markupProcessor) {
            markupProcessor.processMarkup();
        });
    };
    /*
     * PUBLIC INTERFACE
     */
    HandleMarkupProcessingBehavior.prototype.onConsentChanged = function (consent) {
        this.logger.debug("[handleMarkupProcessingBehavior] Consent changed: about to run markup processors");
        this.runMarkupProcessors();
    };
    HandleMarkupProcessingBehavior = __decorate([
        injectable(),
        __param(0, inject(TYPES.userConsent)),
        __param(1, inject(TYPES.config)),
        __param(2, inject(TYPES.logger)),
        __metadata("design:paramtypes", [Object, Config, Object])
    ], HandleMarkupProcessingBehavior);
    return HandleMarkupProcessingBehavior;
}(AbstractOnUserConsentChangeBehavior));
export { HandleMarkupProcessingBehavior };
