var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import "./style.scss";
import { version } from "version";
import template from './template.dot';
import { default as $ } from "cash-dom";
import { AbstractComponent } from "core/entities/ui/AbstractComponent";
import { SettingsDialogCategory } from "./SettingsDialogCategory";
import { Config } from "core/entities/config/Config";
import { UserCategoryBasedConsent } from "core/entities/consent/UserCategoryBasedConsent";
import { SettingsDialogCategoryToggleClasses } from "./SettingsDialogCategoryToggleClasses";
import { TYPES } from "di/types";
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component(config, userConsent, i18n, router, rootState) {
        var _this = _super.call(this, i18n, template) || this;
        _this.config = config;
        _this.userConsent = userConsent;
        _this.router = router;
        _this.rootState = rootState;
        return _this;
    }
    /*
     * ABSTARCT IMPL
     */
    Component.prototype.getTemplateArguments = function () {
        var _this = this;
        var cookiePolicy = this.config.getCookiePolicy();
        return {
            categories: this.config.categories().map(function (category) {
                return new SettingsDialogCategory(category, _this.i18n.getLanguageCode(), _this.userConsent);
            }),
            link: {
                href: cookiePolicy.getLinkHref(this.i18n.getLanguageCode())
            },
            version: version
        };
    };
    Component.prototype.bind = function () {
        var _this = this;
        var me = this;
        this.el.find('.close').on('click', function () { _this.onCloseClick(); });
        this.el.find('button.save').on('click', function () { _this.onSaveBtnClick(); });
        this.el.find('._toggle').on('click', function () { me.onToggleClick(this); });
    };
    /*
     * CONTROLLER
     */
    Component.prototype.onCloseClick = function () {
        //Return to root state
        this.router.transitionTo(this.rootState);
    };
    Component.prototype.onSaveBtnClick = function () {
        var allowedCategoriesIds = [];
        this.el.find('._toggle._on').each(function () {
            var toggleEl = $(this);
            var allowedCategoryId = toggleEl.data('category-id');
            allowedCategoriesIds.push(allowedCategoryId);
        });
        this.userConsent.set(new UserCategoryBasedConsent(allowedCategoriesIds));
        //Return to root state
        this.router.transitionTo(this.rootState);
    };
    Component.prototype.onToggleClick = function (el) {
        var $el = $(el);
        if ($el.hasClass(SettingsDialogCategoryToggleClasses.ON)) {
            $el.removeClass(SettingsDialogCategoryToggleClasses.ON);
            $el.addClass(SettingsDialogCategoryToggleClasses.OFF);
        }
        else {
            $el.addClass(SettingsDialogCategoryToggleClasses.ON);
            $el.removeClass(SettingsDialogCategoryToggleClasses.OFF);
        }
    };
    Component = __decorate([
        injectable(),
        __param(0, inject(TYPES.config)),
        __param(1, inject(TYPES.userConsent)),
        __param(2, inject(TYPES.i18n)),
        __param(3, inject(TYPES.router)),
        __param(4, inject(TYPES.rootState)),
        __metadata("design:paramtypes", [Config, Object, Object, Object, Object])
    ], Component);
    return Component;
}(AbstractComponent));
export { Component };
