/*
 * Models a scenario where the user has expressed a consent
 * about to reject all non necessary cookies
 */
var UserRejectAllConsent = /** @class */ (function () {
    function UserRejectAllConsent() {
    }
    UserRejectAllConsent.prototype.accept = function (visitor) {
        visitor.visitUserRejectAllConsent(this);
    };
    return UserRejectAllConsent;
}());
export { UserRejectAllConsent };
