var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import { Config } from "core/entities/config/Config";
import { ConfigRemovalInfosGenerator } from "./removalInfoGenerators/ConfigRemovalInfosGenerator";
import { DefaultRemovalInfosGenerator } from "./removalInfoGenerators/DefaultRemovalInfosGenerator";
import { GoogleAnalyiticsRemovalInfosGenerator } from "./removalInfoGenerators/GoogleAnalyiticsRemovalInfosGenerator";
import { TYPES } from "di/types";
var CookieRemovalService = /** @class */ (function () {
    function CookieRemovalService(config, logger, cookieSupport) {
        this.logger = logger;
        this.cookieSupport = cookieSupport;
        this.removalInfosGenerators = [
            new ConfigRemovalInfosGenerator(config),
            new DefaultRemovalInfosGenerator(),
            new GoogleAnalyiticsRemovalInfosGenerator(),
        ];
    }
    CookieRemovalService.prototype.applyRemovalRule = function (cookieName, removalRule) {
        /*var newCookie = encodeURIComponent(cookieName) +
                "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";*/
        var newCookie = encodeURIComponent(cookieName) +
            "=; Max-Age=0;";
        if (removalRule.domain) {
            newCookie += "; domain=" + removalRule.domain;
        }
        if (removalRule.path) {
            newCookie += "; path=" + removalRule.path;
        }
        this.cookieSupport.set(newCookie);
    };
    CookieRemovalService.prototype.removeCookie = function (cookieName) {
        var removalInfos = this.removalInfosGenerators.flatMap(function (removalInfoGeneator) {
            return removalInfoGeneator.generateRemovalInfosForCookie(cookieName);
        });
        for (var _i = 0, removalInfos_1 = removalInfos; _i < removalInfos_1.length; _i++) {
            var ithRemovalInfo = removalInfos_1[_i];
            this.logger.debug("[cookieRemovalService] attempting to remove cookie with name '".concat(cookieName, "' with removal info"), ithRemovalInfo);
            this.applyRemovalRule(cookieName, ithRemovalInfo);
            if (!this.cookieSupport.isCookiePresent(cookieName)) {
                this.logger.debug("[cookieRemovalService] removal of cookie with name '".concat(cookieName, "' was successful with removal info"), ithRemovalInfo);
                //Removal was successful! Stop
                break;
            }
        }
    };
    CookieRemovalService = __decorate([
        injectable(),
        __param(0, inject(TYPES.config)),
        __param(1, inject(TYPES.logger)),
        __param(2, inject(TYPES.cookieSupport)),
        __metadata("design:paramtypes", [Config, Object, Object])
    ], CookieRemovalService);
    return CookieRemovalService;
}());
export { CookieRemovalService };
