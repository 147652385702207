import { MultiLanguageStringsSet } from "./MultiLanguageStringsSet";
var CookiePolicy = /** @class */ (function () {
    function CookiePolicy(cookiePolicyDescriptor) {
        this.cookiePolicyDescriptor = cookiePolicyDescriptor;
    }
    CookiePolicy.prototype.getDescriptor = function () {
        return this.cookiePolicyDescriptor;
    };
    CookiePolicy.prototype.getMultiLanguageStringsSet = function () {
        return new MultiLanguageStringsSet(this.getDescriptor().strings);
    };
    CookiePolicy.prototype.getMultiLanguageStrings = function (languageCode) {
        return this.getMultiLanguageStringsSet().getMultiLanguageStrings(languageCode);
    };
    /*
     * PUBLIC INTERFACE
     */
    CookiePolicy.prototype.getMessage = function (languageCode) {
        return this.getMultiLanguageStrings(languageCode).message;
    };
    CookiePolicy.prototype.getLinkHref = function (languageCode) {
        return this.getMultiLanguageStrings(languageCode).linkHref;
    };
    return CookiePolicy;
}());
export { CookiePolicy };
