export var TYPES = {
    config: Symbol("config"),
    i18n: Symbol("i18n"),
    logger: Symbol("logger"),
    cookieCleanupService: Symbol("cookieCleanupService"),
    consentPersistanceService: Symbol("consentPersistanceService"),
    cookieRemovalService: Symbol("cookieRemovalService"),
    cookieSupport: Symbol("cookieSupport"),
    userConsent: Symbol("userConsent"),
    router: Symbol("router"),
    documentLanguage: Symbol("documentLanguage"),
    app: Symbol("app"),
    handleMarkupProcessingBehavior: Symbol("handleMarkupProcessingBehavior"),
    handleUserConsentPersistanceBehavior: Symbol("handleUserConsentPersistanceBehavior"),
    removeCookiesOnUserConsentChangeBehavior: Symbol("removeCookiesOnUserConsentChangeBehavior"),
    manageI18nOnDocumentLanguageChangeBehavior: Symbol("manageI18nOnDocumentLanguageChangeBehavior"),
    rootState: Symbol("rootState"),
    missingConsentState: Symbol("missingConsentState"),
    availableConsentState: Symbol("availableConsentState"),
    settingsState: Symbol("settingsState"),
    missingConsentDialogComponent: Symbol("missingConsentDialogComponent"),
    availableConsentBannerComponent: Symbol("availableConsentBannerComponent"),
    settingsDialogComponent: Symbol("settingsDialogComponent"),
};
