var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractAdapter } from "./AbstractAdapter";
var UrlPrefixAdapter = /** @class */ (function (_super) {
    __extends(UrlPrefixAdapter, _super);
    function UrlPrefixAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UrlPrefixAdapter.prototype.detect = function () {
        var url = window.location.pathname;
        //var siteDirectory = "\/hi\-cookie\-lib";	//dev
        var siteDirectory = ""; //production
        var regex = new RegExp("^".concat(siteDirectory, "/(.{2})/"), 'g');
        var matches = regex.exec(url);
        if ((matches == null) || (matches.length < 2)) {
            throw Error("Url does not seem to have a language code prefix");
        }
        var languageCode = matches[1];
        return languageCode;
    };
    return UrlPrefixAdapter;
}(AbstractAdapter));
export { UrlPrefixAdapter };
