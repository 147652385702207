import { SerializationPayloadType, CURRENT_VERSION } from "./SerializationPayload";
var ConsentVisitor = /** @class */ (function () {
    function ConsentVisitor() {
    }
    /*
     * VISITOR IMPLEMENTATION
     */
    ConsentVisitor.prototype.visitUnknownConsent = function (consent) {
        //Nothing to be serialized
    };
    ConsentVisitor.prototype.visitMissingConsent = function (consent) {
        //Nothing to be serialized
    };
    ConsentVisitor.prototype.visitUserRejectAllConsent = function (consent) {
        this.serializationPayload = {
            version: CURRENT_VERSION,
            type: SerializationPayloadType.UserRejectAllConsent
        };
    };
    ConsentVisitor.prototype.visitUserAcceptAllConsent = function (consent) {
        this.serializationPayload = {
            version: CURRENT_VERSION,
            type: SerializationPayloadType.UserAcceptAllConsent
        };
    };
    ConsentVisitor.prototype.visitUserCategoryBasedConsent = function (consent) {
        this.serializationPayload = {
            version: CURRENT_VERSION,
            type: SerializationPayloadType.UserCategoryBasedConsent,
            allowedCategoriesIds: consent.getAllowedCategoriesIds()
        };
    };
    /*
     * PUBLIC INTERFACE
     */
    ConsentVisitor.prototype.getSerializationPayload = function () {
        return this.serializationPayload;
    };
    return ConsentVisitor;
}());
export { ConsentVisitor };
