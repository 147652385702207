var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable } from "inversify";
import { inject } from "inversify";
import { UserRejectAllConsent } from "core/entities/consent/UserRejectAllConsent";
import { UserCategoryBasedConsent } from "core/entities/consent/UserCategoryBasedConsent";
import { UserAcceptAllConsent } from "core/entities/consent/UserAcceptAllConsent";
import { SerializationPayloadType, CURRENT_VERSION } from "./SerializationPayload";
import { ConsentVisitor } from "./ConsentVisitor";
import { TYPES } from "di/types";
/**
 * Models a behavior that makes the consent persistent every time it changes
 *
 */
var AbstractConsentPersistanceService = /** @class */ (function () {
    function AbstractConsentPersistanceService(logger) {
        this.logger = logger;
    }
    /*
     * INTERNALS
     */
    AbstractConsentPersistanceService.prototype.instantiateConsentFromSerializationPayload = function (serializationPayload) {
        //Validate
        if (serializationPayload.version != CURRENT_VERSION) {
            throw Error("Serialized payload was generated by a previous version");
        }
        //Parse
        switch (serializationPayload.type) {
            case SerializationPayloadType.UserAcceptAllConsent:
                return new UserAcceptAllConsent();
            case SerializationPayloadType.UserRejectAllConsent:
                return new UserRejectAllConsent();
            case SerializationPayloadType.UserCategoryBasedConsent:
                return new UserCategoryBasedConsent(serializationPayload.allowedCategoriesIds);
            default:
                throw Error("Unsupported type");
        }
    };
    /*
     * PUBLIC INTERFACE
     */
    AbstractConsentPersistanceService.prototype.storeConsent = function (consent) {
        this.logger.debug("[consentPersistanceService] About to store consent", consent);
        var consentVisitor = new ConsentVisitor();
        consent.accept(consentVisitor);
        var serializationPayload = consentVisitor.getSerializationPayload();
        if (typeof serializationPayload === 'undefined') {
            //Nothing to store
            return;
        }
        var jsonSerializationPayload = JSON.stringify(serializationPayload);
        var b64JsonSerializationPayload = btoa(jsonSerializationPayload);
        var serialized = b64JsonSerializationPayload;
        this.write(serialized);
    };
    AbstractConsentPersistanceService.prototype.restoreConsent = function () {
        var serialized = this.read();
        var b64JsonSerializationPayload = serialized;
        var jsonSerializationPayload = atob(b64JsonSerializationPayload);
        var serializationPayload = JSON.parse(jsonSerializationPayload);
        var consent = this.instantiateConsentFromSerializationPayload(serializationPayload);
        this.logger.debug("[consentPersistanceService] Restored consent", consent);
        return consent;
    };
    AbstractConsentPersistanceService = __decorate([
        injectable(),
        __param(0, inject(TYPES.logger)),
        __metadata("design:paramtypes", [Object])
    ], AbstractConsentPersistanceService);
    return AbstractConsentPersistanceService;
}());
export { AbstractConsentPersistanceService };
