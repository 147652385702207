/**
 * Google Analytics scenarios:
 *
 * N ----- SITE ----------------------------- DOMAIN---------------------- NOTES
 *
 * 1 ----- www.hi-net.it                      .hi-net.it                   www has been stripped
 * 2 ----- acasatua.ortoservice.it            .ortoservice.com             third level has been stripped (similar to previous case)
 * 3 ----- centrauto.rimini.it			      .centrauto.rimini.it         full domain has been taken but prefixed with '.'
 * 4 ----- www.comune.rimini.it               .comune.rimini.it            www has been stripped (like case 1, but here with third level domain?)
 * 5 ----- armeriaceccoli.com                 .armeriaceccoli.com          full domain has been taken but prefixed with '.' (like case 3, but here without third level domain?)
 * 6 ----- localhost:8383                     localhost                    full domain has been taken (without port part)
 */
var GoogleAnalyiticsRemovalInfosGenerator = /** @class */ (function () {
    function GoogleAnalyiticsRemovalInfosGenerator() {
    }
    GoogleAnalyiticsRemovalInfosGenerator.prototype.generateRemovalInfosForCookie = function (cookieName) {
        var hostname = location.hostname;
        return [
            //Scenario 1 & 2 & 4:	Try to strip WWW, that are the first 3 characters
            {
                domain: hostname.substr(hostname.indexOf('.')),
                path: "/"
            },
            //Scenario 3 & 5:	Prefix with "."
            {
                domain: "." + hostname,
                path: "/"
            },
        ];
    };
    return GoogleAnalyiticsRemovalInfosGenerator;
}());
export { GoogleAnalyiticsRemovalInfosGenerator };
