/*
 * Models a scenario where the system does not know yet if the
 * user has given a consent or not
 */
var UnknownConsent = /** @class */ (function () {
    function UnknownConsent() {
    }
    UnknownConsent.prototype.accept = function (visitor) {
        visitor.visitUnknownConsent(this);
    };
    return UnknownConsent;
}());
export { UnknownConsent };
