var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { injectable } from "inversify";
import { CookieUtils } from "utils/CookieUtils";
var AbstractCookieSupport = /** @class */ (function () {
    function AbstractCookieSupport() {
    }
    AbstractCookieSupport.prototype.getAll = function () {
        var all = this.get()
            .split(';')
            .map(function (piece) {
            return piece.trim();
        });
        return all;
    };
    AbstractCookieSupport.prototype.isCookiePresent = function (cookieName) {
        var found = this.getAll().some(function (ithCookie) {
            var ithCookieName = CookieUtils.getName(ithCookie);
            return (ithCookieName == cookieName);
        });
        return found;
    };
    AbstractCookieSupport.prototype.assertCookieNotPresent = function (cookieName) {
        var found = this.isCookiePresent(cookieName);
        if (found == true) {
            //Assertion failed
            throw Error("Assertion that cookie named '".concat(cookieName, "' is not present has failed"));
        }
    };
    AbstractCookieSupport = __decorate([
        injectable()
    ], AbstractCookieSupport);
    return AbstractCookieSupport;
}());
export { AbstractCookieSupport };
