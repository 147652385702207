import { SettingsDialogCategoryToggleClasses } from "./SettingsDialogCategoryToggleClasses";
import { ConsentVisitor } from "./ConsentVisitor";
import { SettingsDialogCategoryService } from "./SettingsDialogCategoryService";
var SettingsDialogCategory = /** @class */ (function () {
    function SettingsDialogCategory(category, languageCode, userConsent) {
        this.category = category;
        this.languageCode = languageCode;
        this.userConsent = userConsent;
    }
    Object.defineProperty(SettingsDialogCategory.prototype, "id", {
        get: function () {
            return this.category.getId();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingsDialogCategory.prototype, "title", {
        get: function () {
            return this.category.getTitle(this.languageCode);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingsDialogCategory.prototype, "description", {
        get: function () {
            return this.category.getDescription(this.languageCode);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingsDialogCategory.prototype, "toggleClasses", {
        get: function () {
            var categoryToggleClassesPieces = [];
            var consent = this.userConsent.get();
            var consentVisitor = new ConsentVisitor(this.category);
            consent.accept(consentVisitor);
            if (consentVisitor.isToggleOn()) {
                categoryToggleClassesPieces.push(SettingsDialogCategoryToggleClasses.ON);
            }
            else {
                categoryToggleClassesPieces.push(SettingsDialogCategoryToggleClasses.OFF);
            }
            //Needed categories are not editable
            if (this.category.getDescriptor().needed) {
                categoryToggleClassesPieces.push(SettingsDialogCategoryToggleClasses.DISABLED);
            }
            return categoryToggleClassesPieces.join(" ");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingsDialogCategory.prototype, "services", {
        get: function () {
            var _this = this;
            if (typeof this._services === 'undefined') {
                this._services = this.category.services().map(function (service) {
                    return new SettingsDialogCategoryService(service, _this.languageCode);
                });
            }
            return this._services;
        },
        enumerable: false,
        configurable: true
    });
    return SettingsDialogCategory;
}());
export { SettingsDialogCategory };
