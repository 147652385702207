var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { injectable } from "inversify";
import { of, map } from "rxjs";
import { distinctUntilChanged, mergeAll } from "rxjs/operators";
import { HtmlTagAdapter } from "./adapters/HtmlTagAdapter";
import { UrlPrefixAdapter } from "./adapters/UrlPrefixAdapter";
/**
 * Models the language in use by the document.
 *
 */
var DocumentLanguage = /** @class */ (function () {
    function DocumentLanguage() {
        this.adapters = [
            new HtmlTagAdapter(),
            new UrlPrefixAdapter(),
        ];
    }
    DocumentLanguage.prototype.asObservable = function () {
        if (typeof this.observable === 'undefined') {
            this.observable =
                //Create a stream from the available adapters
                of.apply(void 0, this.adapters).pipe(
                //Map every adapter in the stream to their language code streams
                map(function (adapter) {
                    return adapter.asObservable();
                }), 
                //Merge the content of the sub streams into a first-order stream
                mergeAll(), 
                //Ensure no consecutive duplicates are emitted
                distinctUntilChanged());
        }
        return this.observable;
    };
    DocumentLanguage = __decorate([
        injectable(),
        __metadata("design:paramtypes", [])
    ], DocumentLanguage);
    return DocumentLanguage;
}());
export { DocumentLanguage };
