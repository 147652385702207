import { MultiLanguageStringsSet } from "./MultiLanguageStringsSet";
var Cookie = /** @class */ (function () {
    function Cookie(serviceId, cookieDescriptor) {
        this.serviceId = serviceId;
        this.cookieDescriptor = cookieDescriptor;
    }
    Cookie.prototype.getServiceId = function () {
        return this.serviceId;
    };
    Cookie.prototype.isMatching = function (cookieName) {
        var matchedByComparisonCookieDescriptor = this.cookieDescriptor;
        var matchedByRegexCookieDescriptor = this.cookieDescriptor;
        if (typeof matchedByComparisonCookieDescriptor.name !== 'undefined') {
            if (matchedByComparisonCookieDescriptor.name === cookieName) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (typeof matchedByRegexCookieDescriptor.nameRegex !== 'undefined') {
            var result = matchedByRegexCookieDescriptor.nameRegex.test(cookieName);
            return result;
        }
        else {
            throw Error("Malformed cookie descriptor");
        }
    };
    Cookie.prototype.hasRemovalInfo = function () {
        if (typeof this.cookieDescriptor.removalInfos === 'undefined') {
            return false;
        }
        if (this.cookieDescriptor.removalInfos.length == 0) {
            return false;
        }
        return true;
    };
    Cookie.prototype.getRemovalInfos = function () {
        if (typeof this.cookieDescriptor.removalInfos === 'undefined') {
            return [];
        }
        return this.cookieDescriptor.removalInfos;
    };
    Cookie.prototype.getDescriptor = function () {
        return this.cookieDescriptor;
    };
    Cookie.prototype.getMultiLanguageStringsSet = function () {
        return new MultiLanguageStringsSet(this.getDescriptor().strings);
    };
    Cookie.prototype.getMultiLanguageStrings = function (languageCode) {
        return this.getMultiLanguageStringsSet().getMultiLanguageStrings(languageCode);
    };
    Cookie.prototype.getLabel = function (languageCode) {
        return this.getMultiLanguageStrings(languageCode).label;
    };
    Cookie.prototype.getDescription = function (languageCode) {
        return this.getMultiLanguageStrings(languageCode).description;
    };
    Cookie.prototype.getExpiry = function (languageCode) {
        return this.getMultiLanguageStrings(languageCode).expiry;
    };
    return Cookie;
}());
export { Cookie };
