var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
var translations = require("./translations");
import { TYPES } from "di/types";
/*
 * SETUP i18n
 */
import { default as GettextJs } from 'gettext.js';
var i18n = GettextJs( /* options */);
var GettextJsI18nService = /** @class */ (function () {
    function GettextJsI18nService(logger) {
        this.logger = logger;
        for (var key in translations) {
            var entry = translations[key];
            i18n.loadJSON(entry);
        }
    }
    GettextJsI18nService.prototype.setLanguageCode = function (languageCode) {
        i18n.setLocale(languageCode);
    };
    GettextJsI18nService.prototype.getLanguageCode = function () {
        return i18n.getLocale();
    };
    GettextJsI18nService.prototype.__ = function (key) {
        return i18n.__(key);
    };
    GettextJsI18nService = __decorate([
        injectable(),
        __param(0, inject(TYPES.logger)),
        __metadata("design:paramtypes", [Object])
    ], GettextJsI18nService);
    return GettextJsI18nService;
}());
export { GettextJsI18nService };
