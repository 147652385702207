var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import { UnknownConsent } from "core/entities/consent/UnknownConsent";
import { MissingConsent } from "core/entities/consent/MissingConsent";
import { UserRejectAllConsent } from "core/entities/consent/UserRejectAllConsent";
import { UserAcceptAllConsent } from "core/entities/consent/UserAcceptAllConsent";
import { UserCategoryBasedConsent } from "core/entities/consent/UserCategoryBasedConsent";
import { TYPES } from "di/types";
var RootState = /** @class */ (function () {
    function RootState(userConsent, router, availableConsentState, missingConsentState) {
        this.userConsent = userConsent;
        this.router = router;
        this.availableConsentState = availableConsentState;
        this.missingConsentState = missingConsentState;
    }
    RootState.prototype.toString = function () {
        return "RootState";
    };
    /*
     * INTERNALS
     */
    RootState.prototype.onConsentChanged = function (consent) {
        if (consent instanceof UnknownConsent) {
            //Nothing to do
            return;
        }
        if (consent instanceof MissingConsent) {
            this.router.transitionTo(this.missingConsentState);
            return;
        }
        if ((consent instanceof UserRejectAllConsent)
            || (consent instanceof UserAcceptAllConsent)
            || (consent instanceof UserCategoryBasedConsent)) {
            this.router.transitionTo(this.availableConsentState);
            return;
        }
    };
    /*
     * STATE IMPLEMENTATION
     */
    RootState.prototype.onAboutToEnter = function () {
        //This state can be entered freely
        return true;
    };
    RootState.prototype.onEntering = function () {
        //Nothing to do
    };
    RootState.prototype.onEntered = function () {
        var _this = this;
        this.subscription = this.userConsent.asObservable().subscribe(function (consent) {
            _this.onConsentChanged(consent);
        });
    };
    RootState.prototype.onAboutToLeave = function () {
        //This state can be left freely
        return true;
    };
    RootState.prototype.onLeaving = function () {
        //Nothing to do
    };
    RootState.prototype.onLeft = function () {
        this.subscription.unsubscribe();
    };
    RootState = __decorate([
        injectable(),
        __param(0, inject(TYPES.userConsent)),
        __param(1, inject(TYPES.router)),
        __param(2, inject(TYPES.availableConsentState)),
        __param(3, inject(TYPES.missingConsentState)),
        __metadata("design:paramtypes", [Object, Object, Object, Object])
    ], RootState);
    return RootState;
}());
export { RootState };
