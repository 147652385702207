/*
 * Models a scenario where the system knows for shure that the user
 * has not yet given the consent
 */
var MissingConsent = /** @class */ (function () {
    function MissingConsent() {
    }
    MissingConsent.prototype.accept = function (visitor) {
        visitor.visitMissingConsent(this);
    };
    return MissingConsent;
}());
export { MissingConsent };
