var SettingsDialogCategoryServiceCookie = /** @class */ (function () {
    function SettingsDialogCategoryServiceCookie(cookie, languageCode) {
        this.cookie = cookie;
        this.languageCode = languageCode;
    }
    Object.defineProperty(SettingsDialogCategoryServiceCookie.prototype, "label", {
        get: function () {
            return this.cookie.getLabel(this.languageCode);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingsDialogCategoryServiceCookie.prototype, "description", {
        get: function () {
            return this.cookie.getDescription(this.languageCode);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingsDialogCategoryServiceCookie.prototype, "expiry", {
        get: function () {
            return this.cookie.getExpiry(this.languageCode);
        },
        enumerable: false,
        configurable: true
    });
    return SettingsDialogCategoryServiceCookie;
}());
export { SettingsDialogCategoryServiceCookie };
