/*
 * Models a scenario where the user has expressed a consent
 * about to not reject the cookies related to some specific categories
 */
var UserCategoryBasedConsent = /** @class */ (function () {
    function UserCategoryBasedConsent(allowedCategoriesIds) {
        this.allowedCategoriesIds = allowedCategoriesIds;
    }
    UserCategoryBasedConsent.prototype.getAllowedCategoriesIds = function () {
        return this.allowedCategoriesIds;
    };
    UserCategoryBasedConsent.prototype.isCategoryAllowed = function (categoryId) {
        return this.allowedCategoriesIds.some(function (ithAllowedCategoryId) {
            return (ithAllowedCategoryId == categoryId);
        });
    };
    UserCategoryBasedConsent.prototype.accept = function (visitor) {
        visitor.visitUserCategoryBasedConsent(this);
    };
    return UserCategoryBasedConsent;
}());
export { UserCategoryBasedConsent };
