var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractConsentBasedMarkupProcessor } from "core/markupProcessors/AbstractConsentBasedMarkupProcessor";
import { default as $ } from "cash-dom";
import { DomUtils } from "utils/DomUtils";
var MarkupProcessor = /** @class */ (function (_super) {
    __extends(MarkupProcessor, _super);
    function MarkupProcessor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MarkupProcessor.prototype.getTargetElementsSelector = function () {
        return "script";
    };
    MarkupProcessor.prototype.isEnabled = function (el) {
        var typeAttr = el.attr('type');
        return (typeAttr == "text/javascript");
    };
    MarkupProcessor.prototype.enableElementInternal = function ($el) {
        //debugger;
        var el = $el[0];
        /*
         * 1)	Create a new script tag
         */
        var $newEl = $('<script/>');
        var newEl = $newEl[0];
        /*
         * 2)	Copy all attributes
         */
        DomUtils.copyAttributes(el, newEl);
        /*
         * 3)	Copy the content
         */
        DomUtils.copyContent(el, newEl);
        /*
         * 4)	Override the type attribute
         */
        newEl.setAttribute('type', 'text/javascript');
        /*
         * 5)	Perform document alteration
         */
        DomUtils.replaceElement(el, newEl);
    };
    MarkupProcessor.prototype.disableElementInternal = function (el) {
        el.attr('type', 'text/plain');
    };
    return MarkupProcessor;
}(AbstractConsentBasedMarkupProcessor));
export { MarkupProcessor };
