import { Cookie } from "./Cookie";
import { MultiLanguageStringsSet } from "./MultiLanguageStringsSet";
var Service = /** @class */ (function () {
    function Service(serviceId, config) {
        this.serviceId = serviceId;
        this.config = config;
    }
    Service.prototype.getId = function () {
        return this.serviceId;
    };
    Service.prototype.getCategoryId = function () {
        return this.getDescriptor().category;
    };
    Service.prototype.getCategory = function () {
        return this.config.getCategoryById(this.getCategoryId());
    };
    Service.prototype.getDescriptor = function () {
        return this.config.getServiceDescriptorById(this.serviceId);
    };
    Service.prototype.cookies = function () {
        var _this = this;
        if (typeof this._cookies === 'undefined') {
            this._cookies = [];
            this.getDescriptor().cookies.forEach(function (ithCookieDescriptor) {
                var ithCookie = new Cookie(_this.serviceId, ithCookieDescriptor);
                _this._cookies.push(ithCookie);
            });
        }
        return this._cookies;
    };
    Service.prototype.getMatchingCookie = function (cookieName) {
        var foundCookie = this.cookies().find(function (ithCookie) {
            if (ithCookie.isMatching(cookieName)) {
                return true;
            }
        });
        if (typeof foundCookie !== 'undefined') {
            return foundCookie;
        }
        else {
            throw Error("Cookie not found"); //Not found
        }
    };
    Service.prototype.hasMatchingCookie = function (cookieName) {
        try {
            this.getMatchingCookie(cookieName);
            return true;
        }
        catch (e) {
            return false; //Not found
        }
    };
    Service.prototype.getMultiLanguageStringsSet = function () {
        return new MultiLanguageStringsSet(this.getDescriptor().strings);
    };
    Service.prototype.getMultiLanguageStrings = function (languageCode) {
        return this.getMultiLanguageStringsSet().getMultiLanguageStrings(languageCode);
    };
    Service.prototype.getName = function (languageCode) {
        return this.getMultiLanguageStrings(languageCode).name;
    };
    Service.prototype.getDescription = function (languageCode) {
        return this.getMultiLanguageStrings(languageCode).description;
    };
    return Service;
}());
export { Service };
