var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import { version } from "version";
import $ from "cash-dom";
import { TYPES } from "di/types";
var App = /** @class */ (function () {
    function App(removeCookiesOnUserConsentChangeBehavior, rootState, router, logger, manageI18nOnDocumentLanguageChangeBehavior, handleUserConsentPersistanceBehavior, handleMarkupProcessingBehavior) {
        this.removeCookiesOnUserConsentChangeBehavior = removeCookiesOnUserConsentChangeBehavior;
        this.rootState = rootState;
        this.router = router;
        this.logger = logger;
        this.manageI18nOnDocumentLanguageChangeBehavior = manageI18nOnDocumentLanguageChangeBehavior;
        this.handleUserConsentPersistanceBehavior = handleUserConsentPersistanceBehavior;
        this.handleMarkupProcessingBehavior = handleMarkupProcessingBehavior;
    }
    App.prototype.run = function () {
        var _this = this;
        this.logger.debug("[hi-cookie] v.".concat(version));
        /*
         * Update translations when document language changes
         */
        this.manageI18nOnDocumentLanguageChangeBehavior.bind();
        /*
         * Bootstrap router
         */
        $(document).ready(function () {
            _this.router.transitionTo(_this.rootState);
        });
        /*
         * Ensure markup is processed when consent changes
         */
        this.handleMarkupProcessingBehavior.bind();
        /*
         * Ensure a cleanup is performed on cookies when consent changes
         */
        this.removeCookiesOnUserConsentChangeBehavior.bind();
        this.removeCookiesOnUserConsentChangeBehavior.removalFailures$.subscribe(function (cookie) {
            _this.logger.error("[Hi Cookie] Was unable to remove cookie", cookie, "Configuration needs to be adjusted!");
        });
        /*
         * Ensure user consent is restored from storange on startup
         * and stored again when it changes
         */
        this.handleUserConsentPersistanceBehavior.bind();
    };
    App = __decorate([
        injectable(),
        __param(0, inject(TYPES.removeCookiesOnUserConsentChangeBehavior)),
        __param(1, inject(TYPES.rootState)),
        __param(2, inject(TYPES.router)),
        __param(3, inject(TYPES.logger)),
        __param(4, inject(TYPES.manageI18nOnDocumentLanguageChangeBehavior)),
        __param(5, inject(TYPES.handleUserConsentPersistanceBehavior)),
        __param(6, inject(TYPES.handleMarkupProcessingBehavior)),
        __metadata("design:paramtypes", [Object, Object, Object, Object, Object, Object, Object])
    ], App);
    return App;
}());
export { App };
