var DefaultRemovalInfosGenerator = /** @class */ (function () {
    function DefaultRemovalInfosGenerator() {
    }
    DefaultRemovalInfosGenerator.prototype.generateRemovalInfosForCookie = function (cookieName) {
        return [
            //Default domain, with default path
            {
                domain: location.hostname,
                path: '/',
            },
            //Default domain, without path
            {
                domain: location.hostname
            },
            //Default path, without domain
            {
                path: '/',
            },
            //Without path and without domain
            {},
        ];
    };
    return DefaultRemovalInfosGenerator;
}());
export { DefaultRemovalInfosGenerator };
