import { MultiLanguageStringsSet } from "./MultiLanguageStringsSet";
var Category = /** @class */ (function () {
    function Category(categoryId, config) {
        this.categoryId = categoryId;
        this.config = config;
    }
    Category.prototype.getId = function () {
        return this.categoryId;
    };
    Category.prototype.getDescriptor = function () {
        return this.config.getCategoryDescriptorById(this.categoryId);
    };
    Category.prototype.getMultiLanguageStringsSet = function () {
        return new MultiLanguageStringsSet(this.getDescriptor().strings);
    };
    Category.prototype.getMultiLanguageStrings = function (languageCode) {
        return this.getMultiLanguageStringsSet().getMultiLanguageStrings(languageCode);
    };
    Category.prototype.getTitle = function (languageCode) {
        return this.getMultiLanguageStrings(languageCode).title;
    };
    Category.prototype.getDescription = function (languageCode) {
        return this.getMultiLanguageStrings(languageCode).description;
    };
    Category.prototype.services = function () {
        var _this = this;
        return this.config.services().filter(function (service) {
            return (service.getCategoryId() == _this.categoryId);
        });
    };
    return Category;
}());
export { Category };
