var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable } from "inversify";
import { inject } from "inversify";
import { MissingConsent } from "core/entities/consent/MissingConsent";
import { skip } from "rxjs/operators";
import { TYPES } from "di/types";
/**
 * Models a behavior that makes the consent persistent every time it changes
 *
 */
var HandleUserConsentPersistanceBehavior = /** @class */ (function () {
    function HandleUserConsentPersistanceBehavior(userConsent, consentPersistanceService) {
        this.userConsent = userConsent;
        this.consentPersistanceService = consentPersistanceService;
    }
    HandleUserConsentPersistanceBehavior.prototype.bind = function () {
        var _this = this;
        /*
         * 1)	Restore user consent on startup
         */
        try {
            var consent = this.consentPersistanceService.restoreConsent();
            this.userConsent.set(consent);
        }
        catch (e) {
            //Consent was not persisted: now we know for shure that the data is missing -> notify
            this.userConsent.set(new MissingConsent());
        }
        /*
         * 2)	Start persisting the user consent when it changes
         */
        this.subscription = this.userConsent.asObservable()
            .pipe(skip(1) //the restored consent do not have to be persisted
        ).subscribe(function (consent) {
            _this.consentPersistanceService.storeConsent(consent);
        });
    };
    HandleUserConsentPersistanceBehavior.prototype.unbind = function () {
        this.subscription.unsubscribe();
    };
    HandleUserConsentPersistanceBehavior = __decorate([
        injectable(),
        __param(0, inject(TYPES.userConsent)),
        __param(1, inject(TYPES.consentPersistanceService)),
        __metadata("design:paramtypes", [Object, Object])
    ], HandleUserConsentPersistanceBehavior);
    return HandleUserConsentPersistanceBehavior;
}());
export { HandleUserConsentPersistanceBehavior };
