/*
 * DEFAULT IMPL
 */
var _DefaultMultiLanguageStrings = /** @class */ (function () {
    function _DefaultMultiLanguageStrings(localizedStringsDescriptor) {
        this.localizedStringsDescriptor = localizedStringsDescriptor;
        this.init();
    }
    _DefaultMultiLanguageStrings.prototype.init = function () {
        var _this = this;
        var _loop_1 = function (localizedStringId) {
            Object.defineProperty(this_1, localizedStringId, {
                get: function () {
                    return _this.getMultiLanguageString(localizedStringId);
                },
                set: function (value) {
                    throw Error("Localized strings are read-only");
                }
            });
        };
        var this_1 = this;
        for (var localizedStringId in this.localizedStringsDescriptor) {
            _loop_1(localizedStringId);
        }
    };
    _DefaultMultiLanguageStrings.prototype.getMultiLanguageString = function (localizedStringId) {
        var localizedString = this.localizedStringsDescriptor[localizedStringId];
        if (typeof localizedString === 'undefined') {
            throw Error("Localization not found for string '".concat(localizedStringId, "'"));
        }
        return localizedString;
    };
    return _DefaultMultiLanguageStrings;
}());
export var DefaultMultiLanguageStrings = _DefaultMultiLanguageStrings;
/*
 * EMPTY IMPL
 */
var emptyMultiLanguageStringsHandler = {
    get: function (target, prop, receiver) {
        return ""; //Always returns empty string
    },
};
var _EmptyMultiLanguageStrings = /** @class */ (function () {
    function _EmptyMultiLanguageStrings() {
        return new Proxy({}, //target
        emptyMultiLanguageStringsHandler); //handler
    }
    return _EmptyMultiLanguageStrings;
}());
export { _EmptyMultiLanguageStrings };
export var EmptyMultiLanguageStrings = _EmptyMultiLanguageStrings;
