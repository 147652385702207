var IsCategoryAllowedConsentVisitor = /** @class */ (function () {
    function IsCategoryAllowedConsentVisitor(categoryId, config) {
        this.categoryId = categoryId;
        this.config = config;
    }
    /*
     * VISITOR IMPLEMENTATION
     */
    IsCategoryAllowedConsentVisitor.prototype.visitUnknownConsent = function (consent) {
        try {
            var category = this.config.getCategoryById(this.categoryId);
            var categoryDescriptor = category.getDescriptor();
            if (categoryDescriptor.needed) {
                //The cookie is needed: allow it
                this._isCategoryAllowed = true;
            }
            else {
                //Block everything until we're sure which are the allowed categories
                this._isCategoryAllowed = false;
            }
        }
        catch (e) {
            //The specified category is not known: do not allow
            //TODO: log
            this._isCategoryAllowed = false;
        }
    };
    IsCategoryAllowedConsentVisitor.prototype.visitMissingConsent = function (consent) {
        try {
            var category = this.config.getCategoryById(this.categoryId);
            var categoryDescriptor = category.getDescriptor();
            if (categoryDescriptor.needed) {
                //The cookie is needed: allow it
                this._isCategoryAllowed = true;
            }
            else {
                var unneededCategoryDescriptor = categoryDescriptor;
                if (unneededCategoryDescriptor.wanted) {
                    //The user preference is missing, but the category is wanted, so allow it
                    this._isCategoryAllowed = true;
                }
                else {
                    //The user preference is missing and the cookie is not wanted, so do not allow it
                    this._isCategoryAllowed = false;
                }
            }
        }
        catch (e) {
            //The specified category is not known: do not allow
            //TODO: log
            this._isCategoryAllowed = false;
        }
    };
    IsCategoryAllowedConsentVisitor.prototype.visitUserRejectAllConsent = function (consent) {
        try {
            var category = this.config.getCategoryById(this.categoryId);
            var categoryDescriptor = category.getDescriptor();
            if (categoryDescriptor.needed) {
                //The category is needed: allow it
                this._isCategoryAllowed = true;
            }
            else {
                //Al non-needed categories are not allowed
                this._isCategoryAllowed = false;
            }
        }
        catch (e) {
            //The specified category is not known: do not allow
            //TODO: log
            this._isCategoryAllowed = false;
        }
    };
    IsCategoryAllowedConsentVisitor.prototype.visitUserAcceptAllConsent = function (consent) {
        //All categories are allowed, also unknown ones
        this._isCategoryAllowed = true;
    };
    IsCategoryAllowedConsentVisitor.prototype.visitUserCategoryBasedConsent = function (consent) {
        try {
            var category = this.config.getCategoryById(this.categoryId);
            var categoryDescriptor = category.getDescriptor();
            if (categoryDescriptor.needed) {
                //The category is needed: allow it
                this._isCategoryAllowed = true;
            }
            else {
                //Al non-needed categories are not allowed only when explicitly allowed by the user
                this._isCategoryAllowed = consent.isCategoryAllowed(category.getId());
            }
        }
        catch (e) {
            //The specified category is not known: do not allow
            //TODO: log
            this._isCategoryAllowed = false;
        }
    };
    /*
     * PUBLIC INTERFACE
     */
    IsCategoryAllowedConsentVisitor.prototype.isCategoryAllowed = function () {
        return this._isCategoryAllowed;
    };
    return IsCategoryAllowedConsentVisitor;
}());
export { IsCategoryAllowedConsentVisitor };
