import { DefaultMultiLanguageStrings, EmptyMultiLanguageStrings } from "./MultiLanguageStrings";
var defaultLanguageId = 'en';
var MultiLanguageStringsSet = /** @class */ (function () {
    function MultiLanguageStringsSet(localizedStringsSetDescriptor) {
        this.localizedStringsSetDescriptor = localizedStringsSetDescriptor;
    }
    MultiLanguageStringsSet.prototype.getMultiLanguageStringsDescriptor = function (localeId) {
        var localizedStringsDescriptor = this.localizedStringsSetDescriptor[localeId];
        if (typeof localizedStringsDescriptor === 'undefined') {
            throw Error("Localization not found for languageCode '".concat(localeId, "'"));
        }
        return localizedStringsDescriptor;
    };
    MultiLanguageStringsSet.prototype.getMultiLanguageStrings = function (languageCodeId) {
        var localizedStrings;
        try {
            return new DefaultMultiLanguageStrings(this.getMultiLanguageStringsDescriptor(languageCodeId));
        }
        catch (e) {
            if (languageCodeId != defaultLanguageId) {
                return this.getMultiLanguageStrings('en');
            }
            else {
                //Localization not found -> provide empty support
                localizedStrings = new EmptyMultiLanguageStrings();
                return localizedStrings;
            }
        }
    };
    return MultiLanguageStringsSet;
}());
export { MultiLanguageStringsSet };
