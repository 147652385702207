var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractConsentBasedMarkupProcessor } from "core/markupProcessors/AbstractConsentBasedMarkupProcessor";
import { default as $ } from "cash-dom";
import { DomUtils } from "utils/DomUtils";
var MarkupProcessor = /** @class */ (function (_super) {
    __extends(MarkupProcessor, _super);
    function MarkupProcessor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MarkupProcessor.prototype.getTargetElementsSelector = function () {
        return "img";
    };
    MarkupProcessor.prototype.isEnabled = function (el) {
        var srcAttr = el.attr('src');
        return (typeof srcAttr !== "undefined");
    };
    MarkupProcessor.prototype.enableElementInternal = function (el) {
        var src = el.attr('data-src');
        el.attr('src', src);
        el.removeAttr('data-src');
    };
    MarkupProcessor.prototype.disableElementInternal = function ($el) {
        var el = $el[0];
        /*
         * 1)	Create a new script tag
         */
        var $newEl = $('<img/>');
        var newEl = $newEl[0];
        /*
         * 2)	Copy all attributes
         */
        DomUtils.copyAttributes(el, newEl);
        /*
         * 3)	Copy the content
         */
        DomUtils.copyContent(el, newEl);
        /*
         * 4)	Override the data attribute
         */
        var src = $newEl.attr('src');
        $newEl.attr('data-src', src);
        $newEl.removeAttr('src');
        /*
         * 5)	Perform document alteration
         */
        DomUtils.replaceElement(el, newEl);
    };
    return MarkupProcessor;
}(AbstractConsentBasedMarkupProcessor));
export { MarkupProcessor };
