var CookieUtils = /** @class */ (function () {
    function CookieUtils() {
    }
    CookieUtils.getName = function (cookie) {
        var cookieName = cookie.split('=')[0];
        return cookieName;
    };
    CookieUtils.getValue = function (cookie) {
        var cookieName = cookie.split('=')[0];
        return cookieName;
    };
    return CookieUtils;
}());
export { CookieUtils };
