var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractAdapter } from "./AbstractAdapter";
import { default as $ } from "cash-dom";
var HtmlTagAdapter = /** @class */ (function (_super) {
    __extends(HtmlTagAdapter, _super);
    function HtmlTagAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HtmlTagAdapter.prototype.detect = function () {
        var el = $('html');
        var langAttr = el.attr('lang');
        if ((typeof langAttr === 'undefined') || (langAttr == null)) {
            throw Error("Missing lang attribute from html tag");
        }
        var languageCode = langAttr.substr(0, 2);
        return languageCode;
    };
    return HtmlTagAdapter;
}(AbstractAdapter));
export { HtmlTagAdapter };
